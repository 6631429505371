.avatarUploader {
  :global(.ant-upload) {
    border: 1px solid #f2f2f2 !important;
    border-radius: 6px !important;
  }
  :global(.ant-upload-list-item-actions) {
    > a {
      display: none;
    }
  }

}
.imageWrapper{
  :global(.ant-form-item)
  {
    margin: 0px !important;
  }
}
@primary-color: #CABB35;@seconday-color: #03294F;@text-color: #000000;@text-secondary-color: #9B9B9B;@font-family: TimesNewRoman_Regular;