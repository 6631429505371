.letterSpacing {
  letter-spacing: 2px;
  color: var(--primary-white) !important;
}

.MenuItemWrapper {
  width: 100%;
  display: flex;
    justify-content: flex-end;
  background-color: var(--primary-blue);
  line-height: 45px !important;
}

:global(.ant-menu .ant-menu-item-selected) {
  border-bottom: 2px solid var(--primary-yellow) !important;
}
@primary-color: #CABB35;@seconday-color: #03294F;@text-color: #000000;@text-secondary-color: #9B9B9B;@font-family: TimesNewRoman_Regular;