.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c4c4c4;
}

.header {
  background: var(--primary-blue);
  padding: 0rem 2rem;
}

:global(.ant-layout-header) {
  background: var(--primary-blue);
  height: 70px !important;
}

.sider {
  background: #2b4656 !important;
  height: 100%;
  position: fixed !important;
}

.contentContainer {
  background: #ffff !important;
}

.mainLayout {
  margin-left: 200px;
}

.letterSpacing {
  letter-spacing: 1px;
}

.menuArea {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.menuAreaMobile {
  width: 100%;
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
}

.hamBurger {
  font-size: 20px !important;
  color: var(--primary-white);
  margin: 0px 1rem;
}
.languageForm {
  min-width: 150px;
}
.footerSection {
  justify-content: flex-end;
}

.footer {
  background: #ffffff !important;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
  
  .footerMenuContainer,
  .socialIconsContainer,
  .acknowledgmentContainer,
  .brandingContainer {
    display: flex;
    flex-flow: row;
    justify-content: center;
  }
  
  .brandText {
    color: var(--primary-grey) !important;
  }
  
  .brandLink {
    margin: 0rem 5px;
    text-decoration: none;
  }
  
  .footerMenu {
    margin: 0px 1rem;
  }
  
  .socialIcon {
    margin: 1rem;
    font-size: 22px;
  }
  
  .iconLinkTag {
    text-decoration: none;
    color: black;
    cursor: pointer;
  }
  
  .footerDivider {
    width: 100%;
    height: 2px;
    margin: 1rem 0rem;
    background: black;
}

.footerText {
  letter-spacing: 1px;
  color: #c4c4c4;
}

.loginButton {
  margin: 23px 0px 1rem 1rem;
  font-size: 20px;
  color: var(--primary-white);
  cursor: pointer;
}
.rightSectionWrapper {
  display: flex;
  justify-content: flex-end;
}

.footerTextWrapper {
  display: flex;

  // justify-content: space-between;
  >div {
    margin: 2rem 1rem 2rem 0rem;
  }
}

.iconWrapper {
  >div {
    margin: 0rem 1rem;
    cursor: pointer;
  }
}

@media only screen and (max-width: 767px) {
  .footerTextWrapper {
    display: block;

    // justify-content: space-between;
    >div {
      margin: 2rem 1rem 2rem 0rem;
    }
  }

  .rightSectionWrapper {
    display: flex;
    justify-content: flex-end;
  }

  .footerWrapper {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 600px) {
  .footerSection {
    justify-content: flex-start;
  }

  .footerTextWrapper {
    display: inline;

    // justify-content: space-between;
    >div {
      margin: 2rem 0rem 2rem 0rem;
    }
  }

  .rightSectionWrapper {
    display: flex;
    justify-content: flex-start;
  }

  .footerWrapper {
    display: flex;
    justify-content: space-between;
  }

  .iconWrapper {
    >div {
      margin: 0rem 1rem 0rem 0rem;
    }
  }
}

.logoutIcon {
  margin: 0px 20px;
}
@primary-color: #CABB35;@seconday-color: #03294F;@text-color: #000000;@text-secondary-color: #9B9B9B;@font-family: TimesNewRoman_Regular;