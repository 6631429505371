label {
  font-family: ReithQalam_Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
Input {
  text-overflow: ellipsis;
  border: 1px solid #d0d0d0;
  height: 32px !important;
  text-align: right;
  box-sizing: border-box;
  border-radius: 6px !important;
}
.Select {
  text-overflow: ellipsis;
  border: 1px solid #d0d0d0;
  box-sizing: border-box;
  border-radius: 6px !important;
  // :global(.ant-select-selector) {
  //     height: 50px !important;
    //     border: none;
    //   }
}

.listField {
  width: 100% !important;
  gap: 20px !important;
}
.listField > div:first-child {
  width: 100% !important;
}
.accordianTextField {
  border-radius: 6px !important;
}
.inputNumberField {
  text-overflow: ellipsis;
  border: 1px solid #d0d0d0;
  box-sizing: border-box;
  border-radius: 6px !important;
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
}
:global(.ant-input-password) {
  border-radius: 6px !important;
}
.textArea {
  border-radius: 6px !important;
}
:global(.ant-form-item-explain-error) {
  text-align: right !important;
}
.switchButton {
  border-radius: 10px !important ;
}

.textBoxWrapper {
  display: flex !important;
  flex-direction: column !important;
  margin-bottom: 0px !important;

  > div:first-child {
    text-align: right !important;
  }
}
:global(.ant-form-item-label){
  display: flex !important;
}

.noMargin {
  margin: 0px !important;
}
@primary-color: #CABB35;@seconday-color: #03294F;@text-color: #000000;@text-secondary-color: #9B9B9B;@font-family: TimesNewRoman_Regular;