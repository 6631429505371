.blogWrapper{
    direction: rtl;
}

.textBox{
    direction: rtl !important;
}
.addButton{
    margin: 20px 0px;
}
@primary-color: #CABB35;@seconday-color: #03294F;@text-color: #000000;@text-secondary-color: #9B9B9B;@font-family: TimesNewRoman_Regular;