.heading > span {
  color: var(--primary-yellow);
  font-family: ReithQalam_Bold;
  font-weight: bold;
}
.heading {
  color: var(--primary-blue);
  font-family: Univia Pro;
  font-size: 44px;
  margin-bottom: 3px;
}
.secHead {
  margin-top: 80px;
  margin-bottom: 0 !important;
}
.simpleHeading {
  color: var(--primary-blue);
  font-weight: 700;
  font-family: ReithQalam_Bold;
}
.simpleParagraph {
  color: var(--primary-blue);
}

@primary-color: #CABB35;@seconday-color: #03294F;@text-color: #000000;@text-secondary-color: #9B9B9B;@font-family: TimesNewRoman_Regular;