:global(.ant-menu-inline) {
  border-right: none !important;
}

:global(.ant-menu-sub.ant-menu-inline) {
  background: transparent !important;
}

:global(.ant-menu-item-selected::after) {
  border-bottom: none !important;
}

:global(.ant-menu-item-selected) {
  border-bottom: none !important;
}

:global(.ant-menu-item-selected):hover {
  border-bottom: 2px solid var(--primary-yellow) !important;
}
@primary-color: #CABB35;@seconday-color: #03294F;@text-color: #000000;@text-secondary-color: #9B9B9B;@font-family: TimesNewRoman_Regular;