.heading > span {
  color: var(--primary-yellow);
  font-family: Univia Pro;
  font-weight: bold;
}
.heading {
  color: var(--primary-blue);
  font-family: Univia Pro;
  font-size: 44px;
  margin-bottom: 3px;
}
.simpleParagraph {
  color: var(--primary-blue);
  font-family: ReithQalam_Regular;
}

@primary-color: #CABB35;@seconday-color: #03294F;@text-color: #000000;@text-secondary-color: #9B9B9B;@font-family: TimesNewRoman_Regular;