.modalWrapper{
    direction: rtl !important;
}
.modal {
    margin-bottom: 0px !important;
    direction: rtl !important;

  }
  .logoutModalRow {
      margin-bottom: 30px ;
  }
  .modalTitle{
      display: flex;
    direction: rtl !important;

  }
  .warningIcon {
      margin: 0px 10px;
      font-size: 20px;
      color: #BA3BFA;
  }
@primary-color: #CABB35;@seconday-color: #03294F;@text-color: #000000;@text-secondary-color: #9B9B9B;@font-family: TimesNewRoman_Regular;