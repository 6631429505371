button {
  border-radius: 6px !important;
  font-family: ReithQalam_Regular;
  font-style: normal;
  font-weight: 600;
}

.roundButton {
  font-family: ReithQalam_Regular;
  font-weight: 600;
  border-radius: 10px !important;
  height: 40px !important;
  width: 50px !important;
  background: #d9d9d9 !important;
  border: 1px solid #d9d9d9 !important;
}
.ctaButton {
  font-family: ReithQalam_Regular;
  font-weight: 600;
  border-radius: 6px !important;
  border: 1px solid #CABB35 !important;
  color: #CABB35 !important;
  background: transparent;
  height: 40px !important;
  letter-spacing: 0.3px !important;
}
.ButtonHeight {
  height: 40px !important;
  letter-spacing: 0.3px !important;
}

@primary-color: #CABB35;@seconday-color: #03294F;@text-color: #000000;@text-secondary-color: #9B9B9B;@font-family: TimesNewRoman_Regular;