.adminDashboard {
    width: 100%;
    height: 90vh;
    background: #fff;
}
.adminMainDashboard {
    padding: 40px 40px;
    width: 100%;
    height: 100%;
}

.adminSideSection {
    width: 100%;
    height: 100%;
}
.adminMainSection {
    border-left: 2px solid #BA3BFA;
    padding: 0px 30px !important;
}
.aboutMeParagraph{
    display: flex;
    justify-content: flex-end;
}
@primary-color: #CABB35;@seconday-color: #03294F;@text-color: #000000;@text-secondary-color: #9B9B9B;@font-family: TimesNewRoman_Regular;