@font-face {
  font-family: "Nunito_Bold";
    src: url(./Fonts/Nunito/static/Nunito-ExtraBold.ttf)
    format("truetype");
}
@font-face {
  font-family: "Nunito_Regular";
    src: url(./Fonts/Nunito/static/Nunito-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "ReithQalam_Regular";
    src: url(./Fonts/BBCReithQalam/BBC-Reith-Qalam-normal-400-100.otf) format("truetype");
}

@font-face {
  font-family: "ReithQalam_Bold";
    src: url(./Fonts/BBCReithQalam/BBC-Reith-Qalam-normal-700-100.otf) format("truetype");
}

@font-face {
  font-family: "TimesNewRoman_Bold";
    src: url(./Fonts/TimesNewRoman/times-new-roman-bold.ttf) format("truetype");
}

@font-face {
  font-family: "TimesNewRoman_Regular";
    src: url(./Fonts/TimesNewRoman/times-new-roman-regular.ttf) format("truetype");
}
:root {
  --primary-blue: #03294F;
  --primary-yellow: #CABB35;
  --primary-white: #ffff;
  --primary-black: #000000;
  --primary-grey: #9B9B9B;
  --secondary-yellow: #eee6a7a2;
  --light-yellow: #ebde70;
  --primary-page-bg: #FFF4F0;
  --primary-card-bg:  #273441;
  --primary-light-grey: #666666;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.urduFont {
  font-family: ReithQalam_Regular !important;
  text-align: right;
  word-spacing: 4px !important;
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.ql-container {
  font-family: ReithQalam_Regular !important;
  word-spacing: 4px;
  font-size: 18px !important;

  // p {
  //   line-height: 40px !important;
  // }
}

.ql-editor {
  text-align: right !important;
}
.slick-dots li.slick-active button:before {
  color: var(--primary-white) !important;
}

.slick-dots li button:before {
  color: var(--primary-yellow) !important;
}
@primary-color: #CABB35;@seconday-color: #03294F;@text-color: #000000;@text-secondary-color: #9B9B9B;@font-family: TimesNewRoman_Regular;